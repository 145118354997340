export default {
    list: [], //原始类型
    setList(list){
        this.list = list;
    },
    //内容搜索
    searchList(keyword) {
        return this.list.filter((item) => {
            let originJson = item.originJson;
            let title = item.title;
            let is = originJson.includes(keyword);
            let is_title = title.includes(keyword);
            if (is) {
                item.search_content = this.extractContext(originJson, keyword, 30);
                item.search_content = this.jia_ru_hong_se(keyword, item.search_content);
            }

            if (is_title) {

                item.search_title = this.jia_ru_hong_se(keyword, title);
            }

            return is || is_title;
        })
    },
    //关键字前后
    extractContext(text, keyword, contextLength) {
        // 找到关键字的起始索引
        const startIndex = text.indexOf(keyword);

        if (startIndex !== -1) {
            // 计算提取片段的起始和结束索引
            const start = Math.max(0, startIndex - contextLength);
            const end = Math.min(text.length, startIndex + keyword.length + contextLength);

            // 提取并返回片段
            return text.slice(start, end);
        } else {
            // 关键字未找到时的处理
            return "";
        }
      
    },
    //关键字亮红色
    jia_ru_hong_se(keyword, content) {
        const regex = new RegExp(keyword, 'gi');

        // 替换关键字，将其包裹在带有高亮样式的<span>标签中
        content = content.replace(regex, `<span class="highlight-left-xbl">${keyword}</span>`);
        return content;
    }
}