<template>
    <el-dialog title="保存json到列表" :visible.sync="dialogVisible" v-model="dialogVisible" width="400">
        <el-form :model="loginForm" label-width="80px" :rules="rules" ref="formRef">
            <el-form-item label="标题" prop="title">
                <el-input v-model="loginForm.title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remarks">
                <el-input type="text" v-model="loginForm.remarks" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="save">保存</el-button>
                <el-button @click="dialogVisible = false">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
  
<script>
import saveTable from '../../utils/saveTable';
let saveTableObj = new saveTable('jsonList');
import qiaoliang from '../../utils/qiaoliang'
import { ElMessage } from 'element-plus'
export default {
    name: 'LoginDialog',
    components: {

    },
    data() {
        return {
            dialogVisible: false,
            loginForm: {
                title: '',
                remarks: '',
                originJson: ''
            },
            rules: {
                title: [
                    { required: true, message: '请输入标题', trigger: 'blur' },
                ]
            }
        };
    },
    methods: {
        login() {
            console.log("登录信息", this.loginForm);
            // 在这里可以添加登录逻辑
            // this.dialogVisible = false; // 关闭对话框
        },
        //显示模态框
        showModel(textJson) {
            console.log('触发了保存')
            this.loginForm.originJson = JSON.stringify(textJson);
            this.dialogVisible = true;
        },
        //保存存储数据
        save() {

            this.$refs.formRef.validate(err => {
                if (err) {
                    console.log('保存数据', this.loginForm)
                    saveTableObj.addData({
                        type: 1,
                        ...this.loginForm
                    });
                    ElMessage.success('JSON数据保存到本地成功')
                    qiaoliang.leftGengXinShuju();
                    this.dialogVisible = false;
                }

            })


        }
    },
    mounted() {
        console.log('触发一次')
    }
};
</script>
  
<style scoped>
/* 在这里添加组件的样式 */
</style>
  