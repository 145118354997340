<template>
    <div class="main">
        <h3 style="color: aliceblue;margin: 17.5px  0;">JSON 收集</h3>
        <div style="padding: 0 5px;">
            <el-input v-model="search_text" class="w-50 m-2" placeholder="请输入内容搜索" :prefix-icon="Search" clearable  />
        </div>

        <el-scrollbar height="100%">
            <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick" v-if="!search_text">
                <el-tab-pane label="历史" name="li_shi" style="color: #fff;">
                    <div class="list" :class="{ 'active': item.uuid == xuanzhong_uuid }" v-for="(item, index) in list"
                        :key="index" @click="kan(item)" v-show="item.type == 2">
                        <div class="title">{{ item.title }}</div>
                        <el-popconfirm title="是否确认删除" confirm-button-text="删除" cancel-button-text="取消"
                            @confirm="shanchu(item)">
                            <template #reference>
                                <el-icon :color="item.uuid == xuanzhong_uuid ? '#000' : '#fff'" class="no-inherit"
                                    :size="14">
                                    <Delete />
                                </el-icon>
                            </template>
                        </el-popconfirm>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="收藏" name="shou_cang">
                    <div class="list" :class="{ 'active': item.uuid == xuanzhong_uuid }" v-for="(item, index) in list"
                        :key="index" @click="kan(item)" v-show="item.type != 2">
                        <div class="title">{{ item.title }}</div>
                        <el-popconfirm title="是否确认删除" confirm-button-text="删除" cancel-button-text="取消"
                            @confirm="shanchu(item)">
                            <template #reference>
                                <el-icon :color="item.uuid == xuanzhong_uuid ? '#000' : '#fff'" class="no-inherit"
                                    :size="14">
                                    <Delete />
                                </el-icon>
                            </template>
                        </el-popconfirm>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div v-else>
                <!-- 搜索内容 -->
                <div class="list" :class="{ 'active': item.uuid == xuanzhong_uuid }" v-for="(item, index) in list"
                    :key="index" @click="kan(item)"
                    style="flex-direction: column;text-align: left;align-items: self-start;">
                    <div class="title" style="font-weight: bold;"  v-html="item.search_title || item.title"></div>
                    <div class="content" style="padding-top: 15px; word-wrap: break-word;width: 100%;" v-html="item.search_content"></div>
                </div>
            </div>
            <el-empty :image-size="50" v-if="list.length == 0" description="无json保存记录" />
        </el-scrollbar>



    </div>
</template>
  
<script>
import saveTable from '../../utils/saveTable';
import qiaoliang from '../../utils/qiaoliang'
import { eventBus } from '../../utils/eventBus.js';
import SearchFunc from '../../utils/search.js';
import { Search } from '@element-plus/icons-vue'
let saveTableObj = new saveTable('jsonList');
export default ({
    name: 'leftVue',
    data() {
        return {
            Search: Search,
            search_text: '',//搜搜的内容
            activeName: 'li_shi',
            list: [],
            xuanzhong_uuid: ''//选中的uuid
        };
    },
    components: {

    },
    watch: {
        search_text(newValue) {
            if (newValue) {
                let list = saveTableObj.getData();
                SearchFunc.list = list;
                this.list = SearchFunc.searchList(newValue);
                console.log('searchList=>this.list', this.list)
            } else {
                this.list = saveTableObj.getData();
            }
        }
    },
    created() {

    },
    beforeDestroy() {
        eventBus.off('my-left_clear_uuid'); // 移除监听器
    },
    methods: {
        shanchu(item) {
            console.log('点击删除')
            saveTableObj.deleteData(item.uuid)
            setTimeout(() => {
                this.gengxinshuju();
            }, 10)
        },
        //更新数据
        gengxinshuju(type = '') {
            console.log('this.gengxinshuju', type)
            if (type) {
                this.activeName = type;
            }
            this.list = saveTableObj.getData();
        },
        //看数据
        kan(item) {
            this.xuanzhong_uuid = item.uuid;
            this.$emit('gokan', item);
        }
    },
    mounted() {
        this.gengxinshuju();
        qiaoliang.leftGengXinShuju = this.gengxinshuju;
        console.log('EventBus', eventBus)
        if (eventBus) {
            eventBus.on('left_clear_uuid', () => {
                this.xuanzhong_uuid = '';
            });
        }

    }
});
</script>
<style >
.highlight-left-xbl {
    color: red !important;;
}

</style>
  
<style lang="less" scoped>
/* 可以在这里添加一些样式 */
// 强制注入修改
.demo-tabs /deep/ .el-tabs__item {
    color: #fff;
    flex: 1;
}

.demo-tabs /deep/ .el-tabs__item.el-tabs__item.is-active {
    color: var(--el-color-primary);
}

.demo-tabs /deep/ .el-tabs__nav {
    width: 100%;
    display: flex;
}



.main {
    background-color: #343541;
    height: 100vh;



    .main-bot {
        height: 100%;
        overflow-y: auto;
    }

    // display: flex;
    .list {
        // margin-bottom: 15px;
        // border: 1px solid #ccc;
        padding: 15px 15px;
        display: flex;
        color: #fff;
        font-size: 14px;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
            background-color: #fff;
            color: #000;
        }

        .content {}

        &.active {
            background-color: #fff;
            color: #000;
        }
    }

}
</style>
  