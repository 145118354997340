import { v4 as uuidv4 } from 'uuid';
import tools from './tools';
import CryptoJS from "crypto-js";
class TableDataStorage {
    constructor(storageKey) {
        this.storageKey = storageKey;
    }

    getMD5(input) {
        return CryptoJS.MD5(input).toString();
    }

    // 从 localStorage 获取数据
    getData() {
        const dataString = localStorage.getItem(this.storageKey);
        if (dataString) {
            return JSON.parse(dataString);
        }
        return [];
    }

    // 将数据保存到 localStorage
    saveData(data) {
        if (!data.type) data.type == 1;
        localStorage.setItem(this.storageKey, JSON.stringify(data));
    }

    // 添加新数据到现有数据并保存
    addData(newData) {
        newData.uuid = uuidv4();
        newData.time_text = tools.getCurrentDateTime();
        newData.md5 = this.getMD5(newData.originJson) || '';
        if (newData.type == 2) {
            //历史 md5相同的不执行
            if (!this.checkMd5(newData.md5)) return;
        }
        const data = this.getData();
        data.unshift(newData);
        this.saveData(data);
    }

    //校验md5是否相同
    checkMd5(md5) {
        let listData = this.getData();
        let item = listData.find((obj) => {
            if (obj.md5 === md5) {
                return true;
            } else {
                return false;
            }
        });
        console.log('checkMd5item',item)
        return item ? false : true;
    }

    //编辑数据
    editData(newData) {
        let listData = this.getData();
        let item = listData.find(obj => obj.uuid === newData.uuid);
        if (item) {
            item.originJson = newData.originJson; // 例如，修改 name 属性
            item.title = newData.title; // 例如，修改 name 属性
            item.type = newData.type || 1;
            item.remarks = newData.remarks; // 例如，修改 name 属性
            item.md5 = this.getMD5(item.originJson) || '';
        }
        this.saveData(listData);
    }

    //删除数据
    deleteData(uuid) {
        let listData = this.getData();
        const index = listData.findIndex(item => item.uuid === uuid);
        if (index !== -1) {
            listData.splice(index, 1);
        }
        this.saveData(listData);
    }
}
export default TableDataStorage;