<template>
    <div class="contain">
        <div class="btn-dibu">
            <div v-if="!kanObj.uuid" class="backg" style="display: flex;justify-content: space-between;">
                <el-button type="danger" round @click="clearJson()">
                    <el-icon class="el-icon--right" :size="16">
                        <Delete />
                    </el-icon>
                    &nbsp;&nbsp; 清空输入框
                </el-button>
                <div>
                    <el-button type="primary" round @click="save()">收藏JSON</el-button>
                    <el-button type="primary" round @click="exportJson()">导出JSON</el-button>
                    <el-button type="primary" round @click="youbianshifouxianshi()">{{ youbianxianshi ?
                        '隐藏右侧' : '显示右侧' }}</el-button>
                </div>

            </div>
            <div v-else style="display: flex;justify-content: space-between;align-items: center;" class="backg">
                <!-- 编辑模式 -->
                <div>
                    <el-button type="danger" round @click="clearJson()">
                        <el-icon class="el-icon--right" :size="16">
                            <Plus />
                        </el-icon>
                        &nbsp;&nbsp;新建JSON
                    </el-button>


                </div>
                <div style="display: flex;justify-content: space-between;align-items: center;">
                    <div style="display: flex;align-items: center;">
                        标题： <el-input v-model="kanObj.title" placeholder="" style="width: 200px;" />
                    </div>
                    <div style="display: flex;align-items: center;padding-left: 20px;">
                        备注： <el-input v-model="kanObj.remarks" placeholder="请输入备注" style="width: 200px;" />
                    </div>
                    <div style="padding-left: 20px;display: flex;align-items: center;">
                        {{ kanObj.time_text }}
                    </div>
                    <div style="padding-left: 20px;">
                        <el-button type="primary" round @click="saveJson()" v-if="kanObj.type == 2">收藏JSON</el-button>
                        <el-button type="primary" round @click="saveJson()" v-else>保存JSON</el-button>
                        <el-button type="primary" round @click="exportJson()">导出JSON</el-button>
                        <el-button type="primary" round @click="youbianshifouxianshi()">{{ youbianxianshi ?
                            '隐藏右侧' : '显示右侧' }}</el-button>
                    </div>

                </div>
            </div>
        </div>

        <div class="main">
            <div class="mainleft" style="padding-top: 0;">
                <!-- 左侧 -->

                <div class="textarea" style="height: 100%;">


                    <JsonEditorVue v-model="textarea" :modeList="couldView" :currentMode="'code'"
                        @update:modelValue="updateModelValue" :language="language">
                    </JsonEditorVue>

                </div>

            </div>
            <div class="mainright" v-if="youbianxianshi">
                <JsonEditorVue v-model="jsonStr" :modeList="couldView" :currentMode="'view'"
                    @update:modelValue="updateModelValue" :language="language">
                </JsonEditorVue>

            </div>
        </div>

        <saveJsonDialogVue ref="saveJsonDialogVueRef"></saveJsonDialogVue>
    </div>
</template>
  
<script>
import JsonEditorVue from 'json-editor-vue3'
import saveJsonDialogVue from '../dialog/saveJsonDialog.vue';
import saveTable from '../../utils/saveTable';
let saveTableObj = new saveTable('jsonList');
import { eventBus } from '@/utils/eventBus.js';
import { ElMessage } from 'element-plus'
import { Plus, Delete } from '@element-plus/icons-vue'
import qiaoliang from '../../utils/qiaoliang'

export default ({
    name: 'mainVue',
    data() {
        return {
            textarea: undefined,
            jsonStr: {},
            language: ['cn'],
            couldView: [],
            kanObj: {},
            youbianxianshi: true  //右边是否显示
        };
    },
    components: {
        JsonEditorVue,
        saveJsonDialogVue,
        Plus,
        Delete
    },
    watch: {
        textarea(newValue, oldValue) {

            setTimeout(() => {
                if (newValue) {
                    this.jsonStr = newValue;
                    this.chuangjian_lishi();
                }
            }, 1);


        }
    },
    methods: {
        chuangjian_lishi() {
            //创建历史数据
            let jsonStr = '';
            let jsonStr2 = '';
            if (!this.textarea || this.textarea == {}) {
                return '';
            }

            try {
                jsonStr = JSON.stringify(this.textarea, null, 2);
                jsonStr2 = JSON.stringify(this.textarea);
            } catch (e) {
                return;
            }
            if (jsonStr == '{}') {
                return;
            }

            if (!this.kanObj.uuid) {
                saveTableObj.addData({
                    title: jsonStr2.slice(0, 20),
                    remarks: '',
                    originJson: jsonStr,
                    type: 2,
                });
                qiaoliang.leftGengXinShuju();
            }


        },
        clearJson() {
            this.textarea = undefined;
            this.kanObj = {};
            this.jsonStr = {};
            eventBus.emit('left_clear_uuid', {});
        },
        //收藏json
        save() {
            console.log('点击了收藏')
            this.$refs.saveJsonDialogVueRef.showModel(this.textarea);
        },
        //收藏json
        saveJson() {
            this.kanObj.originJson = JSON.stringify(this.textarea);
            this.kanObj.type = 1;
            saveTableObj.editData(this.kanObj)
            ElMessage.success('JSON数据修改收藏成功')
            qiaoliang.leftGengXinShuju('shou_cang');
        },
        updateModelValue(value) {
            console.log('修改了数据', value)
        },
        //数据点击后显示
        gokan(item) {
            this.kanObj = item;
            this.textarea = JSON.parse(item.originJson);

        },
        //右边是否显示
        youbianshifouxianshi() {
            this.youbianxianshi = !this.youbianxianshi;
        },
        //导出json
        exportJson() {
            if (!this.textarea || this.textarea == {}) {
                ElMessage.error('JSON文本格式错误，导出失败');
                return;
            }
            console.log('exportJson', this.textarea);

            const fileName = 'xbl_' + Date.now() + '.json';
            let jsonStr;
            try {
                jsonStr = JSON.stringify(this.textarea, null, 2);
            } catch (e) {
                ElMessage.error('JSON文本格式错误，导出失败');
                return;
            }

            const blob = new Blob([jsonStr], { type: "application/json" });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            URL.revokeObjectURL(link.href);
        }

    },
    mounted() {
        console.log('首次加载')
        this.textarea = {}
        setTimeout(() => {
            this.textarea = undefined;
        }, 10)
    }
});
</script>
  
<style lang="less" scoped>
/* 可以在这里添加一些样式 */
.main {
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 60px);
    overflow: hidden;
    margin-top: 10px;

    .backg {
        // background-color:#343541 ;
        color: #fff;
    }

    /* 允许子元素换行 */
    .mainleft {
        flex: 1;
        padding: 15px;
        height: 100%;
        overflow: hidden;

    }

    .mainright {
        flex: 1;
        // padding: 15px;
        height: 100%;
        overflow: hidden;
    }
}

.contain {
    height: calc(100% - 25px);
    overflow: hidden;
    position: fixed;
    z-index: 99;
    top: 15px;
    width: calc(100% - 240px);

    .btn-dibu {
        padding: 0 15px 15px 15px;
        text-align: left;

    }
}
</style>
  