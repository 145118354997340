<template>
    <div class="common-layout">
        <el-container style="height:100%">
            <el-aside width="200px">
                <div class="aside">
                    <leftVue @gokan="gokan"></leftVue>
                </div>
            </el-aside>
            <el-container>
                <el-header style="padding-left: 0;">
                    <div class="header">
                        <!-- JSON 格式化工具 - 小波龙科技出品 -->
                    </div>
                </el-header>
                <el-main>
                    <div class="main">
                        <mainVue ref="mainVueRef"></mainVue>
                    </div>
                </el-main>
                <!-- <el-footer>
                    <div class="footer">
                        footer
                    </div>
                </el-footer> -->
            </el-container>
        </el-container>
    </div>
</template>
  
<script>
import mainVue from './main.vue';
import leftVue from './left.vue';
export default ({
    name: 'HelloWorld',
    components: {
        mainVue,
        leftVue
    },
    methods:{
        gokan(item){
            this.$refs.mainVueRef.gokan(item);
        }
    }
});
</script>
  
<style scoped lang="less">
.common-layout {
    height: 100%;

    .header {
        width: 100%;
        /* 设定 div 的宽度为100%，使其占满整个容器宽度 */
        height: 60px;
        /* 可以根据需要调整高度 */
        // background-image: url('../../assets/image/1.png');
        // /* 替换为您的图片路径 */
        // background-position: center center;
        // /* 图片居中对齐 */
        // background-size: cover;
        // /* 背景图片覆盖整个div，且不失真 */
        // background-repeat: no-repeat;
        /* 防止背景图片重复 */
        background-color: #343541;

        display: flex;
        align-items: center;
        color: #fff;
        padding-right: 15px;
        justify-content: flex-end;
        // position: fixed;
        // z-index: -999;
    }
    .aside{
        height: 100%;
    }
    .main{
        height: 100%;
        overflow: hidden;
    }
}

/* 可以在这里添加一些样式 */</style>
  